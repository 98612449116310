import React, { useState, useEffect, useMemo, useCallback, createContext, useContext } from "react";
import { useRouter } from "next/router";
import { LANGUAGE, LANGUAGESELECT } from "../utils/messages";
import { LANGUAGE as LANGUAGE2 } from "../components/Form/messages";
import { GlobalContext } from "./GlobalContext";

export const LanguageContext = createContext({});

function LanguageProvider({ children, languagesDefaults = [], languageFormDefault = "" }) {
    const router = useRouter();

    const { state } = useContext(GlobalContext);

    const [firstComponent, setFirstComponent] = useState(true);
    const [languages, setLanguages] = useState(languagesDefaults);
    const [language, setLanguage] = useState({});
    const [isHiddenSelect, setIsHiddenSelect] = useState(false);
    const [isChangeLanguage, setIsChangeLanguage] = useState(false);
    const [isLanguageSelect, setIsLanguageSelect] = useState(false);
    const [languageSelect, setLanguageSelect] = useState({});
    const [languageForm, setLanguageForm] = useState(languageFormDefault);

    const handleSelect = useCallback((select) => {
        setIsChangeLanguage(true);
        const words = {
            ...LANGUAGE[select.value],
            ...LANGUAGE2[select.value],
        };
        const languageCurrent = LANGUAGESELECT[select.value];

        setLanguage(words);
        setLanguageSelect(languageCurrent);
        setIsLanguageSelect(true);
    }, []);

    const setLanguageWords = useCallback((languageWindow, languageFormString = undefined) => {
        const words =
            languageFormString === undefined
                ? {
                      ...LANGUAGE[languageWindow.substring(0, 2)],
                      ...LANGUAGE2[languageWindow.substring(0, 2)],
                  }
                : {
                      ...LANGUAGE[languageFormString],
                      ...LANGUAGE2[languageFormString],
                  };
        setLanguage(words);
    }, []);

    const values = useMemo(
        () => ({
            language,
            languageSelect,
            handleSelect,
            setLanguages,
            isHiddenSelect,
            setIsHiddenSelect,
            isChangeLanguage,
            setIsChangeLanguage,
            languages,
            isLanguageSelect,
            setLanguageForm,
        }),
        [
            language,
            languageSelect,
            handleSelect,
            isHiddenSelect,
            isChangeLanguage,
            languages,
            isLanguageSelect,
        ],
    );

    useEffect(() => {
        const { language: languageWindow } = window.navigator;
        const { isLoading } = state;

        if (isLoading) return setLanguageWords(languageWindow);
        if (languageForm === "") return setLanguageWords(languageWindow);
        if (languageForm !== languageWindow) {
            setLanguageWords(languageWindow, languageForm);
        }
    }, [state, languageForm, setLanguageWords]);

    useEffect(() => {
        if (
            router.locale === undefined ||
            languages?.length < 2 ||
            !firstComponent ||
            languages === undefined ||
            languageForm === ""
        )
            return;

        const { language: languageWindow } = window.navigator;

        router.locale = languageWindow.substring(0, 2);
        const currentLanguages = languages
            .map((v) => LANGUAGESELECT[v.value])
            .filter((item) => item !== undefined);
        const findLanguage = currentLanguages.find((v) => v.value === router.locale);
        const words = {
            ...LANGUAGE[findLanguage !== undefined ? router.locale : languageForm],
            ...LANGUAGE2[findLanguage !== undefined ? router.locale : languageForm],
        };

        setFirstComponent(false);
        setLanguage(words);
        setLanguages(currentLanguages);
        setLanguageSelect(findLanguage ?? LANGUAGESELECT[languageForm]);
    }, [router, firstComponent, languages, languageForm]);

    return <LanguageContext.Provider value={values}>{children}</LanguageContext.Provider>;
}

export default LanguageProvider;
