export const LANGUAGE = {
    es: {
        EMOTIONS_TEXT: "Seleccionar emoción...",
        HOW_WAS_FELT_TEXT: "¿Cómo se sintió?",
        HOW_WISH_IT_WAS_TEXT: "¿Cómo desearía que fuera?",
        MORE_INFO_TEXT: "Ampliar más información",
        SUBMIT_BUTTON_TEXT: "Enviar",
        PREV_BUTTON_TEXT: "Anterior",
        NEXT_BUTTON_TEXT: "Siguiente",
        VALIDATE_BUTTON_TEXT: "Validar",
        FINISH_BUTTON_TEXT: "Terminar",
        SUCCESS_CREATED_SNACKBAR_TITLE: "Respuesta creada exitosamente",
        SUCCESS_CREATED_SNACKBAR_DESCRIPTION: "Su respuesta has sido creada exitosamente",
        SUCCESS_UPDATED_SNACKBAR_TITLE: "Respuesta actualizada exitosamente",
        SUCCESS_UPDATED_SNACKBAR_DESCRIPTION: "Su respuesta has sido actualizada exitosamente",
        ERROR_CREATED_SNACKBAR_TITLE: "Error",
        ERROR_CREATED_SNACKBAR_DESCRIPTION:
            "Ha ocurrido un error, por favor intente más tarde o escribe a soporte@valorpercibido.com",
        ERROR_REQUIRED_QUESTIONS_TITLE: "Pregunta requerida",
        ERROR_PERMISSION_AUDIO_DENIED_DESCRIPTION:
            "Debes otorgar permisos al micrófono en las opciones del navegador para responder esta pregunta",
        ERROR_PERMISSION_VIDEO_DENIED_DESCRIPTION:
            "Debes otorgar permisos al micrófono y a la cámara en las opciones del navegador para responder esta pregunta y recargar nuevamente",
        ERROR_REQUIRED_QUESTIONS_DESCRIPTION: "Por favor responda la pregunta para continuar",
        SAVING_DATA_TEXT: "Guardando respuestas...",
        LOADING_FILES: "Cargando archivos, por favor espere un momento...",
    },
    en: {
        EMOTIONS_TEXT: "Choose emotion...",
        HOW_WAS_FELT_TEXT: "How did it feel?",
        HOW_WISH_IT_WAS_TEXT: "How would you like it to be?",
        MORE_INFO_TEXT: "Expand for more information",
        SUBMIT_BUTTON_TEXT: "Submit",
        PREV_BUTTON_TEXT: "Previous",
        NEXT_BUTTON_TEXT: "Next",
        VALIDATE_BUTTON_TEXT: "Validate",
        FINISH_BUTTON_TEXT: "Finish",
        SUCCESS_CREATED_SNACKBAR_TITLE: "Response created successfully",
        SUCCESS_CREATED_SNACKBAR_DESCRIPTION: "Your response has been created successfully",
        SUCCESS_UPDATED_SNACKBAR_TITLE: "Response updated successfully",
        SUCCESS_UPDATED_SNACKBAR_DESCRIPTION: "Your response has been updated successfully",
        ERROR_CREATED_SNACKBAR_TITLE: "Error",
        ERROR_CREATED_SNACKBAR_DESCRIPTION:
            "An error has occurred, please try again later or contact support at support@valorpercibido.com",
        ERROR_REQUIRED_QUESTIONS_TITLE: "Required question",
        ERROR_REQUIRED_QUESTIONS_DESCRIPTION: "Please answer the question to proceed",
        ERROR_PERMISSION_AUDIO_DENIED_DESCRIPTION:
            "You must grant microphone permissions in the browser options to answer this question",
        ERROR_PERMISSION_VIDEO_DENIED_DESCRIPTION:
            "You must grant microphone and camera permissions in the browser options to answer this question and reload again",
        SAVING_DATA_TEXT: "Saving responses...",
        LOADING_FILES: "Loading files, please wait a moment...",
    },
    fr: {
        EMOTIONS_TEXT: "Sélectionner une émotion...",
        HOW_WAS_FELT_TEXT: "Comment vous êtes-vous senti?",
        HOW_WISH_IT_WAS_TEXT: "Comment souhaiteriez-vous que ce soit?",
        MORE_INFO_TEXT: "Élargir pour plus d'informations",
        SUBMIT_BUTTON_TEXT: "Envoyer",
        PREV_BUTTON_TEXT: "Précédent",
        NEXT_BUTTON_TEXT: "Suivant",
        VALIDATE_BUTTON_TEXT: "Valider",
        FINISH_BUTTON_TEXT: "Terminer",
        SUCCESS_CREATED_SNACKBAR_TITLE: "Réponse créée avec succès",
        SUCCESS_CREATED_SNACKBAR_DESCRIPTION: "Votre réponse a été créée avec succès",
        SUCCESS_UPDATED_SNACKBAR_TITLE: "Réponse mise à jour avec succès",
        SUCCESS_UPDATED_SNACKBAR_DESCRIPTION: "Votre réponse a été mise à jour avec succès",
        ERROR_CREATED_SNACKBAR_TITLE: "Erreur",
        ERROR_CREATED_SNACKBAR_DESCRIPTION:
            "Une erreur s'est produite, veuillez réessayer ultérieurement ou écrire à support@valorpercibido.com",
        ERROR_REQUIRED_QUESTIONS_TITLE: "Question obligatoire",
        ERROR_REQUIRED_QUESTIONS_DESCRIPTION: "Veuillez répondre à la question pour continuer",
        ERROR_PERMISSION_AUDIO_DENIED_DESCRIPTION:
            "Vous devez accorder les autorisations du microphone dans les options du navigateur pour répondre à cette question",
        ERROR_PERMISSION_VIDEO_DENIED_DESCRIPTION:
            "Vous devez accorder les autorisations du microphone et de la caméra dans les options du navigateur pour répondre à cette question et recharger à nouveau",
        SAVING_DATA_TEXT: "Enregistrement des réponses en cours...",
        LOADING_FILES: "Chargement des fichiers en cours, veuillez patienter un instant...",
    },
    de: {
        EMOTIONS_TEXT: "Emotion auswählen...",
        HOW_WAS_FELT_TEXT: "Wie haben Sie sich gefühlt?",
        HOW_WISH_IT_WAS_TEXT: "Wie würden Sie es sich wünschen?",
        MORE_INFO_TEXT: "Weitere Informationen anzeigen",
        SUBMIT_BUTTON_TEXT: "Senden",
        PREV_BUTTON_TEXT: "Vorherige",
        NEXT_BUTTON_TEXT: "Nächste",
        VALIDATE_BUTTON_TEXT: "Überprüfen",
        FINISH_BUTTON_TEXT: "Beenden",
        SUCCESS_CREATED_SNACKBAR_TITLE: "Antwort erfolgreich erstellt",
        SUCCESS_CREATED_SNACKBAR_DESCRIPTION: "Ihre Antwort wurde erfolgreich erstellt.",
        SUCCESS_UPDATED_SNACKBAR_TITLE: "Antwort erfolgreich aktualisiert",
        SUCCESS_UPDATED_SNACKBAR_DESCRIPTION: "Ihre Antwort wurde erfolgreich aktualisiert.",
        ERROR_CREATED_SNACKBAR_TITLE: "Fehler",
        ERROR_CREATED_SNACKBAR_DESCRIPTION:
            "Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut oder schreiben Sie an support@valorpercibido.com.",
        ERROR_REQUIRED_QUESTIONS_TITLE: "Erforderliche Frage",
        ERROR_REQUIRED_QUESTIONS_DESCRIPTION: "Bitte beantworten Sie die Frage, um fortzufahren",
        ERROR_PERMISSION_AUDIO_DENIED_DESCRIPTION:
            "Um diese Frage zu beantworten, müssen Sie in den Browseroptionen Mikrofonberechtigungen erteilen",
        ERROR_PERMISSION_VIDEO_DENIED_DESCRIPTION:
            "Sie müssen in den Browseroptionen Mikrofon- und Kameraberechtigungen erteilen, um diese Frage zu beantworten und erneut zu laden",
        SAVING_DATA_TEXT: "Antworten werden gespeichert...",
        LOADING_FILES: "Dateien werden geladen, bitte warten Sie einen Moment...",
    },
    pt: {
        INPUT_FILE1: "Selecionar um arquivo",
        INPUT_FILE2: "Nenhum arquivo selecionado",
        INDEX_FORM_TITLE: "Como foi sua experiência conosco?",
        LABEL_CODE_FIELD: "Digite o código do ponto ou marca a ser avaliado",
        PLACEHOLDER_CODE_FIELD: "Código",
        INDEX_FORM_SUBMIT_TEXT_BUTTON: "Avaliar",
        ERROR_PAGE_TITLE: "O link não existe",
        ERROR_PAGE_DESCRIPTION: "O link que você está tentando acessar não existe",
        ERROR_PAGE_TEXT_BUTTON: "Ir para o início",
        REQUIRED_QUESTION: "Pergunta obrigatória",
        CODE_DOESNT_EXIST: "O código não existe",
        CODE_INACTIVED: "O código está inativo",
        INVITATION_DOESNT_EXIST: "O convite não existe",
        ERROR_TITLE_GET_FORM: "Ocorreu um erro ao obter o formulário",
        INVITATION_HAS_EXPIRED: "O convite expirou",
        INVITATION_HAS_ANSWERED: "O formulário já foi respondido",
        TOKEN_HAS_EXPIRED: "O convite expirou",
        INVALID_TOKEN: "Convite inválido",
        LOADER_MESSAGE: "Carregando...",
        LOADER_FORM_MESSAGE: "Carregando formulário...",
        LOADER_CHANGE_LANGUAGE: "Mudando idioma...",
        PLACEHOLDER_SELECT_FIELD: "Selecionar...",
        AUDIO: "Grave sua resposta de voz",
        VIDEO: "Grave seu vídeo ao vivo",
        SECONDS_TEXT: "segundos",
        DOES_NOT_SUPPORT_VIDEOS: "Desculpe, seu navegador não suporta vídeos.",
        YOUR_SCORING: "Sua pontuação",
        BRAND_TITLE: "Marca",
        CODE_TITLE: "Código",
        EXPERIENCE_TITLE: "Experiência",
        PROCESS_TITLE: "Processo",
        POINT_TITLE: "Ponto",
        DOWNLOAD_TEXT: "Baixar",
        QR_CODE_ERROR: "O código não existe ou está inativo",
        EMOTIONS_PLACEHOLDER: "Selecionar emoção...",
        HOW_WAS_FELT_PLACEHOLDER: "Como se sentiu?",
        HOW_WISH_IT_WAS_PLACEHOLDER: "Como gostaria que fosse?",
        MORE_INFO_PLACEHOLDER: "Forneça mais informações",
        EVIDENCE_PLACEHOLDER: "Enviar evidência",
        DEFAULT_PLACEHOLDER_INPUTNUMBER: "Por favor, digite um número",
        DEFAULT_PLACEHOLDER_INPUTTEXT: "Por favor, escreva sua resposta",
        DEFAULT_ERROR_MESSAGE_REGEXP: "Não atende à expressão regular",
        DEFAULT_MIN_TEXT_SCALE: "Nada satisfeito",
        DEFAULT_MAX_TEXT_SCALE: "Muito satisfeito",
        YES_LABEL: "Sim",
        NO_LABEL: "Não",
        DE_LABEL: "de",
        PAGE_LABEL: "Página",
        PERCENT_LABEL: "respondido",
        ERROR_PERMISSION_AUDIO_DENIED_DESCRIPTION:
            "Você deve conceder permissões de microfone nas opções do navegador para responder a esta pergunta",
        ERROR_PERMISSION_VIDEO_DENIED_DESCRIPTION:
            "Você deve conceder permissões de microfone e câmera nas opções do navegador para responder a esta pergunta e recarregar novamente",
        MAINTENANCE:
            "A Cemtrik está atualmente em manutenção programada, estará completamente disponível às 4:00 AM GMT-5",
        ERROR_RESPONSES_WITHOUT_INVITATION: "Você não tem um convite válido para acessar este link",
    },
    it: {
        INPUT_FILE1: "Seleziona un file",
        INPUT_FILE2: "Nessun file selezionato",
        INDEX_FORM_TITLE: "Com'è stata la tua esperienza con noi?",
        LABEL_CODE_FIELD: "Inserisci il codice del punto o del marchio da valutare",
        PLACEHOLDER_CODE_FIELD: "Codice",
        INDEX_FORM_SUBMIT_TEXT_BUTTON: "Valuta",
        ERROR_PAGE_TITLE: "Il link non esiste",
        ERROR_PAGE_DESCRIPTION: "Il link che stai cercando di accedere non esiste",
        ERROR_PAGE_TEXT_BUTTON: "Vai alla home",
        REQUIRED_QUESTION: "Domanda obbligatoria",
        CODE_DOESNT_EXIST: "Il codice non esiste",
        CODE_INACTIVED: "Il codice è inattivo",
        INVITATION_DOESNT_EXIST: "L'invito non esiste",
        ERROR_TITLE_GET_FORM: "Si è verificato un errore durante l'ottenimento del modulo",
        INVITATION_HAS_EXPIRED: "L'invito è scaduto",
        INVITATION_HAS_ANSWERED: "Il modulo è già stato risposto",
        TOKEN_HAS_EXPIRED: "L'invito è scaduto",
        INVALID_TOKEN: "Invito non valido",
        LOADER_MESSAGE: "Caricamento...",
        LOADER_FORM_MESSAGE: "Caricamento modulo...",
        LOADER_CHANGE_LANGUAGE: "Cambiamento lingua...",
        PLACEHOLDER_SELECT_FIELD: "Seleziona...",
        AUDIO: "Registrare la tua risposta vocale",
        VIDEO: "Registra il tuo video dal vivo",
        SECONDS_TEXT: "secondi",
        DOES_NOT_SUPPORT_VIDEOS: "Spiacente, il tuo browser non supporta i video.",
        YOUR_SCORING: "Il tuo risultato",
        BRAND_TITLE: "Marca",
        CODE_TITLE: "Codice",
        EXPERIENCE_TITLE: "Esperienza",
        PROCESS_TITLE: "Processo",
        POINT_TITLE: "Punto",
        DOWNLOAD_TEXT: "Scaricare",
        QR_CODE_ERROR: "Il codice non esiste o è inattivo",
        EMOTIONS_PLACEHOLDER: "Seleziona emozione...",
        HOW_WAS_FELT_PLACEHOLDER: "Come ti sei sentito?",
        HOW_WISH_IT_WAS_PLACEHOLDER: "Come desideri che fosse?",
        MORE_INFO_PLACEHOLDER: "Fornisci ulteriori informazioni",
        EVIDENCE_PLACEHOLDER: "Carica prove",
        DEFAULT_PLACEHOLDER_INPUTNUMBER: "Per favore inserisci un numero",
        DEFAULT_PLACEHOLDER_INPUTTEXT: "Per favore scrivi la tua risposta",
        DEFAULT_ERROR_MESSAGE_REGEXP: "Non soddisfa l'espressione regolare",
        DEFAULT_MIN_TEXT_SCALE: "Per niente soddisfatto",
        DEFAULT_MAX_TEXT_SCALE: "Molto soddisfatto",
        YES_LABEL: "Sì",
        NO_LABEL: "No",
        DE_LABEL: "di",
        PAGE_LABEL: "Pagina",
        PERCENT_LABEL: "risposto",
        MAINTENANCE:
            "Cemtrik è attualmente in manutenzione programmata, sarà completamente disponibile alle 4:00 AM GMT-5",
        ERROR_RESPONSES_WITHOUT_INVITATION: "Non hai un invito valido per accedere a questo link",
        ERROR_PERMISSION_AUDIO_DENIED_DESCRIPTION:
            "È necessario concedere le autorizzazioni del microfono nelle opzioni del browser per rispondere a questa domanda",
        ERROR_PERMISSION_VIDEO_DENIED_DESCRIPTION:
            "È necessario concedere le autorizzazioni per microfono e fotocamera nelle opzioni del browser per rispondere a questa domanda e ricaricare di nuovo",
    },
};
